import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';

const SUPPLIER_LOGOS = {
  [Supplier.BT_WHOLESALE]: 'bt_wholesale.png',
  [Supplier.CITYFIBRE]: 'cityfibre.png',
  [Supplier.COLT]: 'colt.png',
  [Supplier.NONE]: 'neos-networks.png',
  [Supplier.OPENREACH]: 'openreach.png',
  [Supplier.SKY]: 'sky.png',
  [Supplier.SKY_ITS]: 'sky.png',
  [Supplier.TALKTALK]: 'talktalk.png',
  [Supplier.VIRGIN]: 'virgin.png',
  [Supplier.VODAFONE]: 'vodafone.png',
  [Supplier.ONAT]: 'onat.png',
  [Supplier.NNAT]: 'onat.png',
  [Supplier.VORBOSS]: 'vorboss.png',
};

function logoForSupplier(supplier: Supplier) {
  return `/supplier_logos/${SUPPLIER_LOGOS[supplier]}`;
}

interface ISupplier {
  className?: string;
  width?: number;
  supplier: Supplier;
}

export const SupplierImage = ({ supplier, className, width }: ISupplier) => (
  <img
    width={width || 162}
    src={logoForSupplier(supplier)}
    className={cx(className, 'supplier_image')}
    alt={presentSupplierNameForPriceTile(supplier)}
  />
);

const SupplierBlock: FunctionComponent<React.PropsWithChildren<ISupplier>> = ({ supplier }) => (
  <div>
    <div>{presentSupplierNameForPriceTile(supplier)}</div>
    <div className="mt-4">
      <SupplierImage supplier={supplier} />
    </div>
  </div>
);

export default SupplierBlock;
